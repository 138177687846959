import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <span style={{ fontWeight: 'bold' }}>Fatmanurgulen</span>&nbsp;tarafından yapılmıştır.
    </footer>
  );
}

export default Footer;
